<script lang="ts">
  import { autorun } from 'mobx';

  import type { ClientUserStore } from 'shared/src';
  import { fly } from 'svelte/transition';
  import Flash from './Flash.svelte';

  export let clientUserStore: ClientUserStore;
  let flashes: ClientUserStore['flashes'];

  autorun(() => {
    flashes = clientUserStore.flashes;
  });
</script>

<div class="sticky top-1 right-1 z-40">
  {#each flashes as flash (flash.id)}
    <div class="absolute right-1 top-1 z-50" in:fly={{ x: 100 }}>
      <Flash {flash} {clientUserStore} />
    </div>
  {/each}
</div>

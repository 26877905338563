<script lang="ts">
  import { errorProvider } from '$lib/error-handling/errorProvider';

  import MaterialIcon from '$lib/MaterialIcon.svelte';
  import { mdiClose } from '@mdi/js';
  import type { FlashRecord } from 'shared/definitions/flash-definitions';
  import type { ClientUserStore } from 'shared/src';
  import { fly } from 'svelte/transition';

  export let clientUserStore: ClientUserStore;
  export let flash: FlashRecord;
  let visible = true;

  function dismiss() {
    visible = false;
    if (flash.deleteable) {
      clientUserStore.api.closeFlash(flash.id).catch((err) => {
        errorProvider.error(err);
      });
    }
  }
</script>

{#if visible}
  <div
    role="alert"
    class="bg-yellow-100 text-yellow-900 rounded-lg shadow-lg"
    out:fly|local={{ x: 100 }}
  >
    <div class="flex justify-start p-2">
      <button class="pr-2" on:click={dismiss}>
        <MaterialIcon size="1em" path={mdiClose} />
        <span class="sr-only">Close button</span>
      </button>
      <div>
        <!-- If there's a link, render as a link -->
        {#if flash.url}
          <a href={flash.url} class="a">{flash.text}</a>
        {:else if flash.messageId}
          {flash.text}
          <a href="/app/messages/{flash.messageId}" class="a">More info</a>
        {:else}
          {flash.text}
        {/if}
      </div>
    </div>
  </div>
{/if}
